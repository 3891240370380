import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import UseAnimations from 'react-useanimations';
import ReactModal from 'react-modal';
import { isEmptyString } from '../../utils';
import { Button } from '../../common/Button';
import { Input } from '../../common/Input';
import { Layer } from '../../common/Layer';
import { useAuthService } from '../../hooks/api/useAuthService';
import { useAuthStore } from '../../hooks/useAuthStore';
import { useModal } from 'react-modal-hook';
import {
  defaultBoxShadow,
  defaultGreyBackgroundColor,
  defaultModalStyle,
  defaultParagraphStyle,
  formButtonStyle
} from '../../styles/default';
import '../../styles/update-password.css';
import { PrimaryButton } from 'common/Button/buttons';

const contentWrapperStyle: CSSProperties = {
  boxShadow: defaultBoxShadow,
  maxWidth: 600,
  width: '95%',
  padding: 20,
  backgroundColor: 'white'
};

const UpdatePassword: FunctionComponent = () => {
  const {
    individual: { email_address },
    logout1
  } = useAuthStore();
  const { changePassword } = useAuthService();

  const [isLoading, setLoading] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordObj, setInputField] = useState({
    email_address,
    old_password: '',
    new_password: '',
    confirm_password: ''
  });

  const { old_password, new_password, confirm_password } = passwordObj;

  useEffect(() => {
    if (
      !isEmptyString(old_password) &&
      !isEmptyString(new_password) &&
      !isEmptyString(confirm_password) &&
      new_password?.length >= 8 &&
      new_password?.length <= 16 &&
      new_password === confirm_password
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [old_password, new_password, confirm_password]);

  const inputsHandler = (e: any) => {
    const { name, value } = e.target;
    setErrorMessage('');
    setInputField({ ...passwordObj, [name]: value });
  };

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    const obj: any = {
      ...passwordObj
    };
    delete obj.confirm_password;
    const isChangePassword = await changePassword(obj);
    if (typeof isChangePassword === 'object') {
      setErrorMessage(isChangePassword.message);
      setLoading(false);
    } else {
      showModal();
      //navigate('/login');
    }
  };

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} style={defaultModalStyle} isOpen>
        <strong style={defaultParagraphStyle}>
          Your password has been changed successfully. please log in to continue.
        </strong>
        <div style={{ paddingTop: 20 }}>
          <a href="/" style={formButtonStyle} onClick={() => logout1()}>
            Login
          </a>
        </div>
      </ReactModal>
    ),
    []
  );

  return (
    <Layer
      center
      fill
      style={{ position: 'relative', backgroundColor: defaultGreyBackgroundColor, height: 'calc(100vh - 70px)' }}
      direction="column"
      width={'100%'}
    >
      <Layer direction="column" style={contentWrapperStyle}>
        <div className="update-password">
          <h2 className="title-update-password">Update Your Password</h2>
          <p className="text-update-password">
            You need to update your password because this is the first time you are signing in.
          </p>
          <label className="field-update-password d-flex align-items-center">
            <p className="label">
              Current password<span className="asterisk-symbol">*</span>
            </p>
            <div>
              <Input onChange={inputsHandler} value={old_password} type="password" name="old_password" />
            </div>
          </label>
          <label className="field-update-password d-flex align-items-center">
            <p className="label">
              New password<span className="asterisk-symbol">*</span>
            </p>
            <div>
              <Input onChange={inputsHandler} value={new_password} type="password" name="new_password" />
              {Boolean(new_password?.trim()) && (new_password?.length < 8 || new_password?.length > 16) ? (
                <div className="error-update-password">Password must me 8 to 16 characters long.</div>
              ) : null}
            </div>
          </label>
          <label className="field-update-password d-flex align-items-center">
            <p className="label">
              Confirm password<span className="asterisk-symbol">*</span>
            </p>
            <div>
              <Input onChange={inputsHandler} value={confirm_password} type="password" name="confirm_password" />
            </div>
          </label>
          <div className="field-update-password d-flex align-items-center m0">
            <p className="label"></p>
            <div>
              {!isEmptyString(new_password) &&
                !isEmptyString(confirm_password) &&
                new_password !== confirm_password && (
                  <div className="error-update-password">Your password and confirmation password do not match.</div>
                )}
              {!isEmptyString(errorMessage) && <p className="error-update-password">{errorMessage}</p>}
              <PrimaryButton
                style={{ marginTop: 10 }}
                loading={isLoading}
                disabled={isDisable}
                icon="edit"
                maxContent={true}
                secondaryButton={true}
                onClick={handleSubmit}
                children="Update Password"
              />
            </div>
          </div>
        </div>
      </Layer>
    </Layer>
  );
};
export { UpdatePassword };
