import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Input } from '../../common/Input';
import { isEmptyString, validateEmail, validatePhoneNo } from './../../utils';
import ReCAPTCHA from 'react-google-recaptcha';

const PreRegistrationForm = (props: any) => {
  const [token, setToken] = useState(null);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const {
    organization_ref_id,
    handleSubmit,
    inputsHandler,
    captchaRef,
    setRequiredField,
    inputField: { name_first, name_middle, name_last, phone, email_address, password, confirm_password, id },
    isRequiredField,
    setActive
  } = props;
  let isPreRegistration = true;

  if (
    !isEmptyString(name_first) &&
    !isEmptyString(name_last) &&
    !validatePhoneNo(phone) &&
    !validateEmail(email_address) &&
    !isEmptyString(password) &&
    !isEmptyString(confirm_password) &&
    password === confirm_password &&
    password?.length >= 8 &&
    password?.length <= 16
  ) {
    isPreRegistration = false;
  }
  const isDisabled = id !== '';

  return (
    <div className="section-pre-registration-form">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <label className="col-form-label">
              First Name<span>*</span>
            </label>
            <Input
              disabled={isDisabled}
              value={name_first}
              name="name_first"
              required={isSubmitForm && isEmptyString(name_first)}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(name_first) && <p className="error-message">Please enter a value</p>}
          </div>
          <div className="col-md-4 col-sm-12">
            <label className="col-form-label">Middle Name</label>
            <Input
              disabled={isDisabled}
              value={name_middle}
              name="name_middle"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label className="col-form-label">
              Last Name<span>*</span>
            </label>
            <Input
              value={name_last}
              disabled={isDisabled}
              name="name_last"
              required={isSubmitForm && isEmptyString(name_last)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(name_last) && <p className="error-message">Please enter a value</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label className="col-form-label">
              Phone<span>*</span>
            </label>
            <Input
              disabled={isDisabled}
              value={phone}
              name="phone"
              required={isSubmitForm && validatePhoneNo(phone)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && validatePhoneNo(phone) && <p className="error-message">Please enter valid Phone No.</p>}
          </div>
          <div className="col-md-6 col-sm-12">
            <label className="col-form-label">
              Email Address<span>*</span>
            </label>
            <Input
              disabled={isDisabled || organization_ref_id !== ''}
              value={email_address}
              name="email_address"
              type="email"
              required={isSubmitForm && validateEmail(email_address)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && validateEmail(email_address) && (
              <p className="error-message">Please enter vaild email address</p>
            )}
          </div>
        </div>
        {!isDisabled && (
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label className="col-form-label">
                Password<span>*</span>
              </label>
              <Input
                value={password}
                name="password"
                required={isSubmitForm && (password !== confirm_password || isEmptyString(password))}
                type="password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
              />
              {isSubmitForm && (
                <p className="error-message">
                  {isEmptyString(password)
                    ? 'Please enter a value'
                    : password?.length < 8 || password?.length > 16
                    ? 'Password must me 8 to 16 characters long.'
                    : password !== confirm_password
                    ? 'Your password and confirmation password do not match.'
                    : null}
                </p>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              <label className="col-form-label">
                Confirm Password<span>*</span>
              </label>
              <Input
                value={confirm_password}
                name="confirm_password"
                required={isSubmitForm && (password !== confirm_password || isEmptyString(confirm_password))}
                type="password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
              />
              {isSubmitForm && (
                <p className="error-message">
                  {isEmptyString(confirm_password)
                    ? 'Please enter a value'
                    : password !== confirm_password
                    ? 'Your password and confirmation password do not match.'
                    : null}
                </p>
              )}
            </div>
          </div>
        )}
        <div>
          <div style={{ marginTop: 15 }}>
            {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY && (
              <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} />
            )}
          </div>
          <button
            disabled={isSubmitForm && !isDisabled && isPreRegistration}
            onClick={() => {
              setIsSubmitForm(true);
              if (!isPreRegistration) {
                if (isDisabled) setActive(1);
                else handleSubmit();
              }
            }}
            className="btn_registration_submit"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default PreRegistrationForm;
