import { ICreateFacetDTO, IFacetDTO, IUpdateBulkFacetDTO, IUpdateFacetDTO } from '../../lib/facet';
import { ICreateIntegrantDTO, IIntegrantDTO } from '../../lib/integrant';
import { ICreateMutationDTO } from '../../lib/mutation';
import { ICreateTransferDTO } from '../../lib/transfer';
import { ICreateTransitDTO } from '../../lib/transit';
import { ILocationDTO } from '../../lib/location';
import { IExhibitDTO, IUpdateBulkExhibitDTO } from '../../lib/exhibit';
import { ReqMethods } from 'use-http';
import { IIntegrantNode } from '../components/GraphNode';
import { titlesAlert } from '../config';
import { IntegrantKind } from '../types/common';
import { IEdge } from '../context/integrantGraph/types';
import { convertToGraphData } from './convertToGraph';
import { defaultBorderColorStyle, defaultGreyBackgroundColor } from '../styles/default';
import { CSSProperties } from 'react';

type ReqMethodsTypes = 'get' | 'post' | 'patch' | 'query' | 'del' | 'delete' | 'mutate' | 'abort';

export const isUndefined = <T>(val: T): boolean => typeof val === 'undefined';
export const isNull = <T>(val: T): boolean => val === null;
export const isEmptyString = (val: string | null): boolean => val === '';
export const isEmptyObj = (obj: any) => Object.keys(obj).length === 0;
export const isPopulated = <T>(val: T[]): boolean => !isNull(val) && val.length > 0;
export const validateEmail = (email: any) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(String(email).toLowerCase());
};
export const getOnlyDigit = (num: any) => parseInt(String(num || 0).replace(/[^0-9]/g, ''));
export const concatArrayOfObjectStrings = (arr: any = [], key: string = '') =>
  arr?.reduce(
    (str: string, obj: any = {}, i: number, arr: any) => `${str} ${obj[key]}${i !== arr?.length - 1 ? ',' : ''}`,
    ''
  );
export const validURL = (str: any) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !pattern.test(str);
};
export const validatePhoneNo = (value: any) => {
  const patt = new RegExp(/^[0-9-]+$/);
  return !patt.test(value);
};

export const returnLocationIfExists = (val: IIntegrantDTO['location']): ILocationDTO | null =>
  val !== null && val.line_1 !== '' ? val : null;

export const defaultFacetAndExhibitValues = (
  val: any
): { title: string; rank: number; description: string; group_id?: number } => {
  let obj: any = {
    title: val.title,
    description: val.description,
    rank: val.rank
  };
  if (val?.group_id) {
    obj.group_id = val?.group_id;
  }
  return obj;
};

export const convertToCreateFacets = (val: IFacetDTO[]): any =>
  isPopulated(val) && val[0].title !== ''
    ? val.map(f => {
        return defaultFacetAndExhibitValues(f);
      })
    : [];

export const convertToUpdateBulkFacets = (facet: IFacetDTO[]): any =>
  isPopulated(facet) && facet[0].title !== ''
    ? facet.map(f => {
        return {
          id: f.id,
          ...defaultFacetAndExhibitValues(f)
        };
      })
    : [];

export const convertToUpdateFacet = (facet: IFacetDTO): any => {
  return defaultFacetAndExhibitValues(facet);
};

export const convertToUpdateBulkExhibits = (exhibit: IExhibitDTO[]): IUpdateBulkExhibitDTO[] =>
  isPopulated(exhibit) && exhibit[0].title !== ''
    ? exhibit.map(f => {
        return {
          ...defaultFacetAndExhibitValues(f),
          id: f.id,
          exhibit_type_id: f.exhibit_type_id,
          exhibit_type: f.exhibit_type,
          location: returnLocationIfExists(f.location)
        };
      })
    : [];

export const returnTransitIfExists = (
  currentTransit: ICreateTransitDTO,
  isTransit: boolean
): ICreateTransitDTO | undefined => (isTransit ? currentTransit : undefined);

export const returnMutationIfExists = (parent_integrant_id: string, batch_id: string): ICreateMutationDTO | undefined =>
  !isEmptyString(parent_integrant_id)
    ? {
        integrant_id: batch_id
      }
    : undefined;

export const convertToIntegrantCreate = (
  integrant: IIntegrantDTO,
  parent_integrant_id: string,
  batch_id: string,
  integrantKind: IntegrantKind,
  currentTransit?: ICreateTransitDTO,
  currentTransfer?: ICreateTransferDTO
): ICreateIntegrantDTO => {
  const createIntegrant = {
    integrant_type_id: integrant.integrant_type_id,
    organization_id: integrant.organization_id,
    tracking_id: integrant.tracking_id,
    primary: integrant.primary,
    published: integrant.published,
    exhibits: integrant.exhibits,
    location: returnLocationIfExists(integrant.location),
    facets: convertToCreateFacets(integrant.facets)
  };
  switch (integrantKind) {
    case IntegrantKind.Transit:
      return {
        ...createIntegrant,
        origination_transit: currentTransit,
        origination_mutation: undefined,
        origination_transfer: undefined
      };
    case IntegrantKind.Transfer:
      return {
        ...createIntegrant,
        origination_transit: undefined,
        origination_mutation: undefined,
        origination_transfer: currentTransfer
      };
    default:
      return {
        ...createIntegrant,
        origination_mutation: returnMutationIfExists(parent_integrant_id, batch_id),
        origination_transit: undefined,
        origination_transfer: undefined
      };
  }
};

export const returnObjectWithoutEmptyFields = <T>(obj: T): void =>
  Object.entries(obj).forEach(([key, val]: [string, keyof IIntegrantNode]) => {
    if (val === null || val.length === 0 || val === undefined) delete obj[key as never];
  });

export const req = async <T>(request: any, type: ReqMethodsTypes, url?: string, body?: T): Promise<T> => {
  const response: T = await request[type](`${url}`, isUndefined(body) ? body : {});
  return response;
};

export const generateClientID = (): string => {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

export const generateInheritedFacetsRank = (facets: IFacetDTO[]): IFacetDTO[] => {
  return facets.map((f, index) => {
    return { ...f, rank: (index + 1) * 1000 };
  });
};

export const isInheritedRank = (rank: number): boolean => {
  return rank.toString().includes('000');
};

export function getParams(key: string): string | null {
  const hashes = window.location.search.slice(window.location.search.indexOf('?') + 1).split('&');
  const params: { [key: string]: string } = {};
  hashes.map(hash => {
    const [k, val] = hash.split('=');
    params[k] = decodeURIComponent(val);
    return params;
  });
  return params[key] !== undefined ? params[key] : null;
}

export const replaceSubString = (value: string): ((replaceFrom: string) => (replaceTo: string) => string) => {
  return (replaceFrom: string): ((replaceTo: string) => string) => {
    const updatedString = value.split(replaceFrom);
    return (replaceTo: string): string => {
      return updatedString.join(replaceTo);
    };
  };
};

export const reduceRank = <T extends { rank: number }>(arr: T[]): number =>
  arr.reduce((value, { rank }) => (value > rank ? value : rank), -1) + 1;

export const nodeIsKind = (node: IIntegrantNode, integrantKind: IntegrantKind): boolean =>
  node.edge_type === integrantKind;

export const returnKindTitle = (edge: IEdge): string => {
  switch (edge.edge_type) {
    case IntegrantKind.Transit:
      return titlesAlert.transit;
    case IntegrantKind.Transfer:
      return titlesAlert.transfer;
    case IntegrantKind.Mutation:
      return titlesAlert.mutation;
    default:
      return ``;
  }
};

function handleUpdateRank<T extends { rank: number }>(
  array: T[],
  currentIndex = 0,
  currentUpdatedFacets: T[] = [],
  currentInheritedRank?: number
): T[] {
  const currentItem = array[currentIndex];
  const beforeExists = typeof array[currentIndex - 1] !== 'undefined';

  if (currentIndex >= array.length) {
    return currentUpdatedFacets;
  }

  if (isInheritedRank(currentItem.rank)) {
    return handleUpdateRank(array, currentIndex + 1, [...currentUpdatedFacets, currentItem], currentItem.rank);
  } else {
    if (beforeExists) {
      return handleUpdateRank(
        array,
        currentIndex + 1,
        [...currentUpdatedFacets, { ...currentItem, rank: currentUpdatedFacets[currentIndex - 1].rank + currentIndex }],
        currentInheritedRank
      );
    }

    return handleUpdateRank(
      array,
      currentIndex + 1,
      [...currentUpdatedFacets, { ...currentItem, rank: 0 }],
      currentInheritedRank
    );
  }
}

const rightContentWrapperStyle = (selectedIntegrantTypeId: string | undefined): CSSProperties => {
  return {
    position: 'absolute',
    top: 0,
    right: selectedIntegrantTypeId !== '' ? '0' : '-100%',
    ...defaultBorderColorStyle,
    backgroundColor: defaultGreyBackgroundColor,
    height: '100%',
    display: 'flex'
  } as CSSProperties;
};

const getTimeNumber: any = new Date().getTime();

export const replaceSpecialCharAndWhiteSpace = (str: string): string => {
  return str.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(' ', '');
};

export const verifyEnvironment = (str: string): boolean => {
  if (str === 'live') {
    return window.location.host === 'partner.healthloq.com';
  } else if (str === 'dev') {
    return window.location.host === 'healthloqproducer-dev.azurewebsites.net';
  } else if (str === 'qa') {
    return window.location.host === 'healthloqproducer-qa.azurewebsites.net';
  } else {
    return window.location.hostname === 'localhost';
  }
};

export const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export { convertToGraphData, handleUpdateRank, rightContentWrapperStyle, getTimeNumber };

export const getLocalStorageItems = () => {
  const selectedSubscription = localStorage.getItem('selectedSubscription');
  return {
    selectedSubscription
  };
};

export function numberWithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function abbrNum(number: any) {
  number = parseInt(number);
  let decPlaces = 0;
  if (number >= 1000 && number < 1000000) {
    decPlaces = 0;
  } else if (number >= 1000000 && number < 1000000000) {
    decPlaces = 1;
  } else if (number >= 1000000000 && number < 10000000000000) {
    decPlaces = 2;
  } else if (number >= 10000000000000) {
    decPlaces = 3;
  }
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ['k', 'm', 'b', 't'];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i]?.toUpperCase();

      // We are done... stop
      break;
    }
  }

  return number;
}

export const getCoordinates = (coords: any): string => {
  return !coords[0] && !coords[1] ? JSON.stringify([0, 0]) : JSON.stringify(coords);
};
