import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../utils/api-interceptor';
import { useAuthStore } from '../../hooks/useAuthStore';
import { formAxiosHeader } from '../../config';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import { FormInput } from './FormInput';
import { useToastStore } from '../../hooks/useToastStore';
import { isEmptyString, isEmptyObj, validateEmail, validatePhoneNo } from './../../utils';
import '../../styles/subscription.css';
import { PrimaryButton } from 'common/Button/buttons';
import { useHistory } from 'react-router-dom';

const Profile = (props: any) => {
  const { individual, setIndividual } = useAuthStore();
  const history = useHistory();
  const { isOpenToast } = useToastStore();
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [individualObj, setIndividualObj] = useState<any>({});
  const { id, organization_id, email_address, name_first, name_middle, name_last, phone, status } = individualObj;

  const getIndividual = async () => {
    await axiosInstance
      .get('individual/' + individual.id, formAxiosHeader(individual.access_token))
      .then((response: any) => {
        setPageLoading(false);
        setIndividualObj(response);
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    getIndividual();
  }, []);

  const inputsHandler = async (e: any) => {
    const { name, value } = e.target;
    setIndividualObj({ ...individualObj, [name]: value });
  };

  if (pageLoading || isEmptyObj(individualObj)) {
    return (
      <div className="subscription-page-load">
        <Loading title={LoadingTitle.Loading + ' Your Profile'} />
      </div>
    );
  }
  function submitHandler() {
    const obj = {
      individual_id: id,
      organization_id,
      email_address,
      name_first,
      name_middle,
      name_last,
      phone,
      status
    };
    if (isEmptyString(name_first) || isEmptyString(name_last) || isEmptyString(email_address) || isEmptyString(phone)) {
      isOpenToast('isError', 'Please fill out the required fields.');
      return false;
    }
    if (validateEmail(email_address)) {
      isOpenToast('isError', 'Please enter a valid email address.');
      return false;
    }
    if (validatePhoneNo(phone)) {
      isOpenToast('isError', 'Please enter vaild Phone No.');
      return false;
    }
    setLoading(true);
    axiosInstance
      .post('individual/edit', obj, formAxiosHeader(individual.access_token))
      .then((response: any) => {
        isOpenToast('success', 'Your Profile details has been successfully updated.');
        setIndividual({ ...individual, email_address, name_first, name_middle, name_last, phone, status });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        return err;
      });
  }

  return (
    <div className="cp">
      <div className="container">
        {/* <p className='page-title'>Profile</p> */}
        <div className="cs-title">
          <div className="d-flex justify-content-between align-items-center profile-heading">
            <div className="subscription-title">Profile</div>
            <PrimaryButton
              onClick={() => history.push('/update-password')}
              children={'Update Your Password'}
              icon="edit"
              maxContent={true}
              secondaryButton={true}
            />
          </div>
        </div>
        <div className="row align-items-center _pt15">
          <div className="col-md-6">
            <FormInput
              isFirst={true}
              label={
                <>
                  First Name<span className="asterisk-symbol">*</span>
                </>
              }
              required={true}
              name="name_first"
              value={name_first}
              error={isEmptyString(name_first) && 'Please enter first name.'}
              inputsHandler={inputsHandler}
            />
            <FormInput
              label={<>Middle Name</>}
              name="name_middle"
              required={false}
              value={name_middle}
              inputsHandler={inputsHandler}
            />
            <FormInput
              label={
                <>
                  Last Name<span className="asterisk-symbol">*</span>
                </>
              }
              error={isEmptyString(name_last) && 'Please enter last name.'}
              required={true}
              name="name_last"
              value={name_last}
              inputsHandler={inputsHandler}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              isFirst={true}
              label={
                <>
                  Email<span className="asterisk-symbol">*</span>
                </>
              }
              error={
                isEmptyString(email_address)
                  ? 'Please enter email address.'
                  : validateEmail(email_address)
                  ? 'Please enter a valid email address.'
                  : ''
              }
              required={true}
              name="email_address"
              value={email_address}
              inputsHandler={inputsHandler}
            />
            <FormInput
              label={
                <>
                  Phone<span className="asterisk-symbol">*</span>
                </>
              }
              error={
                isEmptyString(phone)
                  ? 'Please enter phone number.'
                  : validatePhoneNo(phone)
                  ? 'Please enter vaild Phone No.'
                  : ''
              }
              required={true}
              name="phone"
              value={phone}
              inputsHandler={inputsHandler}
            />
            <FormInput
              label="Account Status"
              name="status"
              required={false}
              value={status}
              isDisable={true}
              inputsHandler={inputsHandler}
            />
          </div>
        </div>
        <div className="btn-strip _pt15">
          <PrimaryButton
            style={{ marginLeft: 'auto' }}
            icon="edit"
            children={`Update Profile Details`}
            onClick={() => submitHandler()}
            maxContent={true}
            secondaryButton={true}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export { Profile };
